if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
}

$(document).ready(function () {
        $(".j-preventDefault").click(prvDft);

        //Replace SVG
        $('img.j-svg').each(function () {
            // Replace all SVG .j-svg images with inline SVG
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');
                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }
                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');
                // Replace image with new SVG
                $img.replaceWith($svg);
            }, 'xml');
        });
    }
);

function prvDft(event) {
    event.preventDefault();
};
$(document).ready(function () {
        UIkit.util.on("#modal-consultation", 'hide', function () {
            clearModal($('#modal-consultation').find('form').first());
        });
    UIkit.util.on("#modal-booking", 'hide', function () {
        clearModal($('#modal-booking').find('form').first());
    });
    }
);

function clearModal(form){
    form.find('input,textarea,select,button').attr('disabled', false).prop('disabled', false);
    form.find('.spinner').addClass(AjaxForm.invisibleClass);
    form.find('.message').empty().removeClass(AjaxForm.successClass).removeClass(AjaxForm.errorClass);
}

function YaOrderGoals() {
    // yaCounter25864289.reachGoal('REG_FOR_COURSES');
    //console.log('Yandex.Metrica Goals');
    return true;
}